import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Flex, Text } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Section from '../components/section'
import IntroSection from '../components/intro-section'
import Container from '../components/container'
import SectionTitle from '../components/section-title'
import ButtonLink from '../components/button-link'
import '../styles/properties.css'
import '../styles/tooltip.css'

const ParcelyPage = () => {
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "parcely-hero.jpg" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      properties: file(relativePath: { eq: "pozemky-legenda-27.png" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const backgroundImage = data.backgroundImage.childImageSharp.fluid

  return (
    <Layout>
      <SEO title="Parcely" />

      <Hero
        backgroundImage={backgroundImage}
        title="Stavební parcely"
      />

      <IntroSection />

      <Section variant="light">
        <Container>
          <Flex flexDirection="column" alignItems="center">
            <SectionTitle as="h2">Chcete začít stavět?</SectionTitle>

            <Text
              sx={{
                maxWidth: '780px',
                textAlign: 'center',
                mb: 4,
              }}
            >
              Máme pro Vás již dva schválené projekty
            </Text>

            <ButtonLink to="/hotove-projekty">Hotové projekty</ButtonLink>
          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

export default ParcelyPage
